<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";

export default({
  name: "app",
  data() {
    return {};
  },
  methods: {},
});
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f5fa;
}

a {
  text-decoration: none;
  color: none;
}
body,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
div {
  font-weight: normal;
}
input {
  border: none;
  outline: none;
}
del{
  text-decoration: line-through;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}
.el-menu {
  border: none;
}

// 公共样式
.p-flex {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.p-fl-c-c {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.p-fl-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.p-flex-cen {
  display: flex;
  align-items: center;
}
.textcol {
  color: #0D9C2C;
}
.colfff {
  color: #fff;
}
.col000 {
  color: #000;
}
.col666 {
  color: #666666;
}
.col999 {
  color: #999999;
}
.colab {
  color: #ABABAB;
}
.colfc {
  color: #fcfcfc;
}
.colmoney {
  color: #EB2710;
}
.fz-12{
  font-size: 12px;
}
.fz-20 {
  font-size: 20px;
}
.fz-22 {
  font-size: 22px;
}
.fz-24 {
  font-size: 24px;
}
.fz-28 {
  font-size: 28px;
}
.fz-30 {
  font-size: 30px;
}
.fz-32 {
  font-size: 32px;
}
.fz-34 {
  font-size: 34px;
}
.fz-36 {
  font-size: 36px;
}
.fz-40 {
  font-size: 40px;
}
.font-w-b {
  font-weight: bold;
}
.bg-fff {
  background: #fff;
}

.pad20 {
  padding: 0 20px;
}
.pad40 {
  padding: 0 40px;
}
.mar-t-30 {
  margin-top: 30px;
}
.line-10{
  height: 10px;
}
.line-30{
  height: 30px;
}
.t-al-cen {
  text-align: center;
}
.n-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
}

 //样式
 .loading_div {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
}

.el-cascader-panel { height: 200px; }
</style>
