import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:{},
    setting:{}
  },
  mutations: {
    setuser(state, payload) {
			state.user = payload
		},
		setsetting(state, payload) {
			state.setting = payload
		}
  },
  actions: {
  },
  modules: {
  }
})
