import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../user/login.vue')
  },
  {
    path:'/p',
    name:'index',
    meta: { title: '首页' },
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/index.vue"),
    children:[
      {
        path: 'home',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
          table:()=> import(/* webpackChunkName: "about" */ "../views/Home.vue"),
        }
      },
      {
        path: 'cashOutAccount',
        name: 'cashOutAccount',
        meta: { title: '提现管理' },
        components:  {
          table:()=> import( "../views/cashOutAccount.vue"),}
      },
      {
        path: 'balanceCashOut',
        name: 'balanceCashOut',
        meta: { title: '余额提现' },
        components:  {
          table:()=> import( "../views/balanceCashOut.vue"),}
      },
      {
        path: 'cashOutList',
        name: 'cashOutList',
        meta: { title: '提现列表' },
        components:  {
          table:()=> import( "../views/cashOutList.vue"),}
      },
      {
        path: 'partner',
        name: 'partner',
        meta: { title: '合伙人管理' },
        components:  {
          table:()=> import( "../views/partner.vue"),}
      },
      {
        path: 'promoter',
        name: 'promoter',
        meta: { title: '推广员管理' },
        components:  {
          table:()=> import( "../views/promoter.vue"),}
      },
      {
        path: 'partnerCashOut',
        name: 'partnerCashOut',
        meta: { title: '提现管理' },
        components:  {
          table:()=> import( "../views/partnerCashOut.vue"),}
      },
      {
        path: 'businessManage',
        name: 'businessManage',
        meta: { title: '商家管理' },
        components:  {
          table:()=> import( "../views/businessManage.vue"),}
      },
      {
        path: 'businessCashOut',
        name: 'businessCashOut',
        meta: { title: '商家提现' },
        components:  {
          table:()=> import( "../views/businessCashOut.vue"),}
      },
      {
        path: 'butlerDetail',
        name: 'butlerDetail',
        meta: { title: '入账明细' },
        components:  {
          table:()=> import( "../views/butlerDetail.vue"),}
      },
      {
        path: 'paidOrders',
        name: 'paidOrders',
        meta: { title: '已支付订单' },
        components:  {
          table:()=> import( "../views/paidOrders.vue"),},
        
      },
      {
        path: 'verifiedRecords',
        name: 'verifiedRecords',
        meta: { title: '已核销记录' },
        components:  {
          table:()=> import( "../views/verifiedRecords.vue"),},
      },
      {
        path: 'recordCanceled',
        name: 'recordCanceled',
        meta: { title: '已取消记录' },
        components:  {
          table:()=> import( "../views/recordCanceled.vue"),},
      },
      {
        path: 'refundRecord',
        name: 'refundRecord',
        meta: { title: '已退款记录' },
        components:  {
          table:()=> import( "../views/refundRecord.vue"),},
      },
      {
        path: 'withdrawalSettings',
        name: 'withdrawalSettings',
        meta: { title: '提现设置' },
        components:  {
          table:()=> import( "../views/withdrawalSettings.vue"),}
      },
      {
        path: "paidOrders/ordersDetails",
        name: "ordersDetails",
        meta: { title: '订单详情', belong: true },
        components:  {
          table:()=> import( "../views/modal/ordersDetails.vue"),}
      },
      {
        path: "home/noticeInfo",
        name: "noticeInfo",
        meta: { title: '代理公告详情', belong: true },
        components:  {
          table:()=> import( "../views/modal/noticeInfo.vue"),}
      },
      {
        path: "home/balanceDetail",
        name: "balanceDetail",
        meta: { title: '金额明细', belong: true },
        components:  {
          table:()=> import( "../views/modal/balanceDetail.vue"),}
      },
      

      
      // {
      //   path: 'about',
      //   name: 'about',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/About.vue"),
      //   }
      // },
      // {
      //   path: 'goods',
      //   name: 'goods',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/goods.vue"),
      //   }
      // },
      // {
      //   path: '/order/tradeDetail',
      //   name: 'tradeDetail',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/order/tradeDetail.vue"),
      //   }
      // },
      // {
      //   path: '/order/verification',
      //   name: 'verification',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/order/verification.vue"),
      //   }
      // },
      // {
      //   path: '/order/refund',
      //   name: 'refund',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/order/refund.vue"),
      //   }
      // },
      // {
      //   path: '/finance/dailyBenifits',
      //   name: 'dailyBenifits',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/finance/dailyBenifits.vue"),
      //   }
      // },
      // {
      //   path: '/finance/withdrawal',
      //   name: 'withdrawal',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/finance/withdrawal.vue"),
      //   }
      // },
      // {
      //   path: '/finance/account',
      //   name: 'account',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/finance/account.vue"),
      //   }
      // },
      // {
      //   path: '/finance/invoice',
      //   name: 'invoice',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/finance/invoice.vue"),
      //   }
      // },
      // {
      //   path: '/data/goodsData',
      //   name: 'goodsData',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/data/goodsData.vue"),
      //   }
      // },
      // {
      //   path: '/data/storeData',
      //   name: 'storeData',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/data/storeData.vue"),
      //   }
      // },
      // {
      //   path: '/data/tradeData',
      //   name: 'tradeData',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/data/tradeData.vue"),
      //   }
      // },
      // {
      //   path: '/time/staff',
      //   name: 'staff',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/time/staff.vue"),
      //   }
      // },
      // {
      //   path: 'exhibition',
      //   name: 'exhibition',
      //   components: {
      //     table:()=> import(/* webpackChunkName: "about" */ "../views/exhibition.vue"),
      //   }
      // }
    ],
  },
 
  // {
  //   path: '/user',
  //   name: 'user',
  //   children:[
  //     {
  //       path: 'login',
  //       name: 'login',
  //       component: () => import(/* webpackChunkName: "about" */ '../user/login.vue')
  //     },
  //   ]
  // },
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router
