//按需引入element组件
import {
    Form,
    FormItem,
    Select,
    Option,
    OptionGroup,
    Input,
    Tree,
    Dialog,
    Row,
    Col,
    Button,
    Popconfirm,
    PageHeader
} from 'element-ui'

const element = {
    install: function (Vue) {
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Select)
        Vue.use(Option)
        Vue.use(OptionGroup)
        Vue.use(Input)
        Vue.use(Tree)
        Vue.use(Dialog)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Button)
        Vue.use(Popconfirm)
        Vue.use(PageHeader)
    }
}

export default element