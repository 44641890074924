import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import api from "./network/api.js";

import echarts from 'echarts'

import './assets/css/tailwindcss.css'

Vue.prototype.$echarts = echarts;
Vue.prototype.$api = api;//请求接口api

// element ui 按需引入
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import element from './element/index'
Vue.use(ElementUI);
Vue.use(element)

// vue-cookies配置
import cookies from 'vue-cookies'
Vue.prototype.$cookies = cookies;


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
